.profile-page {
  padding: 20px;

  .header {
    position: relative;
    height: 200px;
  }

  .header-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-info {
    position: absolute;
    bottom: -65%;
    left: 50px;

    .profile-name {
      font-size: 28px;
      font-weight: 500;
      color: #333;
    }

    .profile-title {
      font-size: 16px;
      color: #666;
    }

    @media screen and (max-width: 578px) {
      left: 50%;
      bottom: -93%;
      transform: translate(-50%, 0);
      flex-direction: column;
      align-items: center;
      .profile-name,
      .profile-title {
        text-align: center;
      }
    }
  }

  .available-time-slots-wrapper {
    position: absolute;
    right: 10%;
    top: 65%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 570px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%;
    @media screen and (max-width: 1575px) {
      display: none;
    }
  }

  .profile-avatar {
    width: 175px !important;
    height: 175px !important;
    border: 8px solid white;
    border-radius: 50%;
    @media screen and (max-width: 578px) {
      border: 4px solid white;
    }
  }

  .main-content {
    padding: 140px 20px 20px;
    @media screen and (max-width: 578px) {
      padding: 200px 0 20px;
    }
    .available-time-slots-wrapper-main {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      max-width: 570px;
      @media screen and (min-width: 678px) {
        min-width: 570px;
      }
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      display: none;
      @media screen and (max-width: 1575px) {
        display: block;
      }
      @media screen and (max-width: 676px) {
        max-width: 90vw;
      }
    }
  }

  .left-column {
    gap: 10px;
  }

  .send-message-btn {
    background-color: #6264a7 !important;
    color: white !important;
  }

  .reviews-btn {
    background-color: white !important;
    color: #6264a7 !important;
    border: 1px solid #6264a7 !important;
  }

  .section-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .topic-label {
    background-color: #fef3eb !important;
    padding: 15px !important;
    margin: 5px;
    div {
      color: #f88634;
    }
  }
  .interest-label,
  .skill-label {
    background-color: #eeefff !important;
    padding: 15px !important;
    margin: 5px;
    div {
      color: #3d3e66;
    }
  }

  .schedule-btn {
    background-color: #6264a7 !important;
    color: white !important;
  }

  .right-column {
    @media screen and (max-width: 678px) {
      width: 100%;
    }
  }

  .right-column > * {
    margin-bottom: 20px;
  }

  .review-item {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .review-name {
    font-weight: bold;
  }

  .review-title,
  .review-date {
    color: #666;
    font-size: 14px;
  }

  .review-content {
    margin-top: 10px;
  }

  .date-slider {
    margin-bottom: 20px;
  }

  .date-slider .slick-slide {
    padding: 0 5px;
  }

  .date-btn {
    width: 100%;
    height: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: white !important;
    color: #333 !important;
    border: 1px solid #ddd !important;
    border-radius: 20px !important;
    padding: 5px 15px !important;
    margin: 5px auto !important;
    font-size: 14px;
  }
}
