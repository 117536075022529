@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;600&display=swap");

body {
  /* overflow-y: hidden; */
}

.Charts {
  height: 100%;
  background-color: transparent;
  /* overflow: scroll; */
}

.Charts.s-ctr .chart-loading-wrapper {
  border-bottom: 1px solid #d6d6d6;
}

.Charts h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: initial;
  color: #252423;
}

.Charts .ui-table__cell {
  display: flex;
  justify-content: center;
}

.select-class-dropdown .MuiFormControl-root {
  width: 100% !important;
}
.select-class-type .MuiFormControl-root {
  width: 100% !important;
}
.filter-multi-select .MuiFormControl-root {
  width: 100% !important;
}

/* .leftControls {
  margin: 0 0 0 0 !important;
} */

.Charts .ui-table__row.ui-table__header {
  font-weight: 600;
  font-size: 0.85rem;
}

@media screen and (max-width: 500px) {
  .Charts .ui-table__row {
    font-size: 10px;
  }
}

/* .Table .ui-table__header .ui-table__cell:first-child {
  width: 20vw;
  min-width: 20vw;
  max-width: 20vw;
  word-break: break-all;
} */

.BoxChart {
  grid-column: span 12;
  /* margin-left: 50px; */
  margin-right: 50px;
  align-self: center;
  align-items: center;
  /* justify-self: center; */
}

/* .Table .ui-table__header .ui-table__cell:nth-last-child(2) {
  padding: 0;
  width: 6vw;
  min-width: 6vw;
  max-width: 6vw;
} */

/* .Table .ui-table__header .ui-table__cell:nth-last-child(1),
.Table .ui-table__header .ui-table__cell:nth-last-child(3),
.Table .ui-table__header .ui-table__cell:nth-last-child(4),
.Table .ui-table__header .ui-table__cell:nth-last-child(5) {
  width: 4.5vw;
  min-width: 4.5vw;
  max-width: 4.5vw;
  word-break: break-all;
} */

.Table .ui-table__cell:first-child {
  width: 19vw;
  min-width: 19vw;
  max-width: 19vw;
  word-break: break-all;
  justify-content: flex-start;
}

/* ============ Analytics ================= */

.Analytics {
  /* padding-top: 20px; */
  /* min-height: calc(100vh - 130px); */
  /* height: 100%; */
  /* position: fixed; */
  /* overflow: auto; */
  /* padding-bottom: 20px; */
  width: 100%;
  background-color: white;
}

/* @media screen and (min-width: 1024px) {
  .Analytics {
    zoom: 0.7;
  }
} */

.Analytics .BoxChart {
  /* max-width: 53.846vw !important; */
  /* height: 16vh; */
  height: auto;
  overflow: visible;
  width: max-content;
  place-self: flex-start;
}

.Analytics .PieChart {
  /* max-width: 46.154vw !important; */
  /* height: 37vh; */
  overflow: visible;
  /* overflow-x: hidden; */
  padding: 0 0 0 0 !important;
}

.PieChart foreignObject {
  width: 325px;
}

.Analytics .ColumnChart {
  /* max-width: 53.846vw !important; */
  /* overflow: scroll; */
}

.ColumnChart foreignObject {
  /* display: block; */
  overflow: visible;
}

.Analytics .Table {
  /* max-width: 46.154vw !important; */
  /* overflow: scroll; */
  /* margin-top: 8vh; */
}

.Analytics .ui-segment {
  box-shadow: none;
  background-color: transparent;
}

.Boxes .ui-segment {
  /* min-width:105px;
    height:105px; */
  /* height:70px; */
}

.Fcabin {
  /* font-family: monospace; */
  font-family: "Cabin", sans-serif;
}

.Schedule .Boxes .seg-Box {
  width: 130px !important;
  background-color: white;
}

.Schedule .Boxes .mobile-seg-Box {
  width: 99px !important;
  background-color: white;
}

.Boxes .seg-Box {
  width: 110px;
  padding: 4px 0 0;
  border: 1px solid rgb(179, 179, 179);
  /* box-shadow: none; */
  text-align: center;
  /* margin-right: 0 !important; */
  margin: 0.3125rem !important;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  border: none;
  border-radius: 4px;
}

.Boxes .mobile-seg-Box {
  width: 110px;
  padding: 4px 0 0;
  border: 1px solid rgb(179, 179, 179);
  /* box-shadow: none; */
  text-align: center;
  /* margin-right: 0 !important; */
  margin: 0.3125rem !important;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  border: none;
  border-radius: 4px;
}

.seg-Box .ui-seg1 {
  width: auto;
  height: 20px;
  line-height: 16px;
  padding: 0px 8px;
  box-shadow: none;
  font-size: 13px;
  text-align: left;
  font-family: "Karla", sans-serif;
  color: #212529;
}

.mobile-seg-Box .ui-seg1 {
  width: auto;
  height: 20px;
  line-height: 16px;
  padding: 0px 8px;
  box-shadow: none;
  font-size: 13px;
  text-align: left;
  font-family: "Karla", sans-serif;
  color: #212529;
}

.seg-Box .seg-header {
  color: black;
  margin: 0px;
  padding-bottom: 10px;
}

.mobile-seg-Box .seg-header {
  color: black;
  margin: 0px;
  padding-bottom: 10px;
}

.PieChart .chart-seg-1,
.PieChart .chart-seg-2 {
  max-width: 320px;
}
.pie-chart-upper {
  display: none !important;
}

@media screen and (max-width: 1442px) {
  .pie-chart-upper {
    display: block !important;
    width: 100%;
  }
}
@media screen and (max-width: 1442px) {
  .pie-chart-lower {
    display: none !important;
  }
}

/* ============ Analytics Over ================= */

.grid .gridRows .ui-table__row {
  min-height: 48px;
  margin: 10px 0;
}

.grid .gridRows {
  max-width: 46.154vw;
  max-height: 35vh;
  overflow: auto;
}

.grid .gridHeader {
  border-bottom: 1px solid #b3b3b3;
  border-top: 1px solid #b3b3b3;
}

@media only screen and (max-width: 767px) {
  .grid .gridHeader {
    max-width: 95vw;
  }
}
@media only screen and (max-width: 600px) {
  .grid .gridHeader .ui-table__cell__content,
  .grid .gridHeader .ui-button__content {
    font-size: 11px;
    white-space: normal;
  }
}

@media only screen and (max-width: 500px) {
  .grid .gridHeader .ui-table__cell__content,
  .grid .gridHeader .ui-button__content {
    font-size: 8px;
    white-space: normal;
  }
}

.exportGrid .gridRows .ui-table__row {
  min-height: 48px;
}

.exportGrid .gridRows {
  min-width: 80vw;
  max-height: 50vh;
  overflow: auto;
}

.exportGrid .gridHeader {
  border-bottom: 1px solid #b3b3b3;
  border-top: 1px solid #b3b3b3;
}

.Schedule .teacher-drop .ui-image {
  width: 2.5rem;
}

.ui-seg1 .ui-box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* selectors */

.Charts .ClassSelector .ui-dropdown__container {
  width: 14rem !important;
}

.Charts .ClassSelector .ui-dropdown__selected-items {
  max-height: 4.5rem;
}

.Charts .ClassSelector .ui-list {
  /* width: 17rem !important; */
  /* wait - check first */
}

.Charts .ui-dropdown__selecteditem__header {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.SmallSelector > div {
  width: 100%;
}

.Charts .SmallSelector .ui-dropdown__container {
  width: 7rem !important;
}

.Charts .SmallSelector .ui-list {
  width: 7rem !important;
}

.Charts .SmallSelector .ui-dropdown__selecteditem__header {
  max-width: 40px;
}

.Charts .ColonySelector .ui-dropdown__selected-items,
.Charts .SmallSelector .ui-dropdown__selected-items {
  max-height: 4.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.Charts .ColonySelector .ui-dropdown__container {
  width: 11rem !important;
}

.Charts .ColonySelector .ui-list {
  width: 11rem !important;
}

/* ========multi selector=========== */
.Charts .css-48ayfv,
.Charts .css-1r4vtzz {
  box-shadow: none;
  background-color: #f3f2f1;
  /* margin-top: 16px; */
  color: #212529;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 200px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  /* width: 100%; */
}

.mobile-multi-select > div > button,
.mobile-multi-select > div > div {
  width: 100%;
}

.Charts .css-1qpjby2,
.Charts .css-1gpjby2 {
  position: absolute !important;
  right: 8px !important;
}

.Charts .css-1qprcsu-option {
  background-color: transparent !important;
  font-weight: normal !important;
}

.Charts .css-1vr111p-option,
.Charts .css-1qprcsu-option {
  cursor: pointer;
}

.Charts .css-1vr111p-option:hover,
.Charts .css-1qprcsu-option:hover {
  background-color: #f3f2f1 !important;
}

.Charts .css-1pcexqc-container {
  box-shadow: rgb(0 0 0 / 10%) 0px 0.8rem 1rem -0.2rem;
  width: max-content !important;
  width: 100% !important;
}

.Charts .filterSelected .css-1r4vtzz,
.Charts .filterSelected .css-48ayfv {
  background-color: #6264a7;
  color: #ffffff;
  box-shadow: none;
}

.Charts .css-1v99tuv {
  max-width: 90%;
}

/* ========multi selector over=========== */
/* ========segment=========== */
.Charts .seg1,
.Controls .seg1 {
  font-weight: 600;
  font-size: 14px;
  box-shadow: none;
  height: 10px;
  padding: 0 8px;
}

.Charts .seg2,
.Controls .seg2 {
  box-shadow: none;
  padding: 14px 0px;
  background-color: transparent;
}

.seg > div > div > button {
  width: 100%;
}

.mobile-dropdown > div {
  width: 100%;
}

.date-hidden > div {
  display: none;
}

.Charts .seg3,
.Controls .seg3 {
  box-shadow: none;
  padding: 0px;
  background-color: transparent;
}

.Charts .chart-seg-1 .Charts .chart-seg-2 {
  grid-column: span 1;
  justify-self: center;
}

.Analytics .ui-flex {
  justify-content: center;
}

.Charts .apexcharts-menu-item.exportCSV {
  display: none;
}

/* ========responsive=========== */

@media only screen and (max-width: 1450px) {
  .Analytics .BoxChart {
    width: auto;
  }
}

@media only screen and (min-width: 1300px) {
  .Analytics .BoxChart {
    max-width: 50vw;
  }
}

@media only screen and (max-width: 1300px) {
  .Analytics .BoxChart .Boxes {
    flex-wrap: wrap !important;
  }
}

@media only screen and (min-width: 1300px) {
  .Analytics .BoxChart {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 1300px) {
  .Analytics .BoxChart {
    grid-column: span 13 / auto !important;
  }

  .Analytics .PieChart {
    grid-column: span 13 / auto !important;
  }

  .Analytics .ColumnChart {
    grid-column: span 13 / auto !important;
  }

  .Analytics .Table {
    grid-column: span 13 / auto !important;
    margin-top: 0vh;
    padding-right: 0;
  }

  .Analytics .ui-grid {
    grid-template-rows: auto !important;
  }

  .grid .gridRows {
    max-height: 50vh;
    max-width: 100vw;
    scrollbar-width: thin;
  }

  .pieFlex {
    flex-wrap: wrap;
  }

  .Charts.s-ctr .Analytics {
    /* padding-bottom: 100px; */
  }

  /* .Table .ui-table__header .ui-table__cell:nth-last-child(1){
    width: 20vx !important;
  }
  .Table .ui-table__header .ui-table__cell:nth-last-child(2),
  .Table .ui-table__header .ui-table__cell:nth-last-child(3),
  .Table .ui-table__header .ui-table__cell:nth-last-child(4),
  .Table .ui-table__header .ui-table__cell:nth-last-child(5),
  .Table .ui-table__header .ui-table__cell:nth-last-child(6){
    width: 10vw !important;
  } */
  .Table .ui-table__cell:first-child {
    width: 30vw;
    min-width: 30vw;
    max-width: 30vw;
    word-break: normal;
    justify-content: flex-start;
  }

  .PieChart .chart-seg-1,
  .PieChart .chart-seg-2 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    text-align: center;
    margin-right: 0 !important;
  }

  /* .Table .ui-table__header .ui-table__cell:nth-last-child(2) .ui-box {
    margin-left: -8vw !important;
    position: relative !important;
  }

  .Table .ui-table__header .ui-table__cell:nth-last-child(3) .ui-box {
    margin-left: -5vw !important;
    position: relative !important;
  }

  .Table .ui-table__header .ui-table__cell:nth-last-child(4) .ui-box {
    margin-left: -3vw !important;
    position: relative !important;
  } */
}

/* .Table .ui-table__header .ui-table__cell:nth-last-child(5) .ui-box {
  margin-left: -1vw !important;
  position: relative !important;
} */

@media only screen and (max-width: 1060px) {
  .Charts.h-ctr .Analytics {
    /* height: calc(100vh - 61px); */
  }
}

@media only screen and (max-width: 880px) {
  /* .grid .gridRows .ui-table__row {
    min-height: auto;
    height: auto !important;
  } */
  .BoxChart {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 350px) {
  /* .grid .gridRows .ui-table__row {
    min-height: auto;
    height: auto !important;
  } */
  .BoxChart {
    margin-left: 5px;
    margin-right: 5px;
  }

  .TableGrid {
    width: -webkit-fill-available;
    margin-left: 3rem;
  }
}

@media only screen and (max-width: 500px) {
  .apexcharts-text.apexcharts-xaxis-label tspan {
    display: none;
  }

  .apexcharts-text.apexcharts-xaxis-label tspan:nth-child(1) {
    display: block;
  }
}

.close_icon {
  background-color: #f3f2f1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;
  display: flex;
  padding-right: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.mobile_close_icon {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;
  display: flex;
  padding-right: 5px;
  cursor: pointer;
  position: relative;
  z-index: 10;
}

.classSelector .ui-box {
  display: flex;
}
.classSelector .ui-box .css-1r4vtzz {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: 200px;
  /* width: 100%; */
  box-shadow: none;
}

.classSelector .ui-box .multi-select {
  display: flex;
}

.classSelector .ui-box .mobile-multi-select {
  display: flex;
}

.mobile-classSelector .ui-box {
  display: flex;
  width: 93%;
}
.mobile-classSelector .ui-box .css-1r4vtzz {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none;
}

.mobile-classSelector .ui-box .multi-select {
  display: flex;
  width: 100%;
}

.mobile-classSelector .ui-box.mobile-multi-select {
  display: flex;
  width: 100%;
}

.classSelector .ui-dropdown__container {
  width: 100%;
}

.multi-select > div {
  /* width: 100%; */
}

.mobile-multi-select > div {
  /* width: 100%; */
}

.multi-select {
  /* width: 100%; */
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
}

.mobile-multi-select {
  /* width: 100%; */
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
}

/* .classSelector .ui-box .multi-select > div > div:nth-child(3){
  display: none;
} */
